import { ParsedUrlQuery } from 'node:querystring';
import { User } from 'firebase/auth';
import { Action, Dispatch } from 'redux';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { Property } from '@services/hafh/types/generated';
import { fitBounds, fitMapToArea } from '@utils/search-map';
import { LANG_LOCALE } from '@utils/types';

const api = apiClient(API_TYPES.API);

type SearchMapState = {
  endDate: Date | null;
  filterSettings: {
    coins: { max: number; min: number };
    properties_count: number;
  };
  latestQueryParams: null | ParsedUrlQuery;
  mapRef: { map: any; maps: any } | null;
  properties: Property[];
  property: null | Property;
  propertyCount: number;
  selectedProperties: Property[];
  showRefreshButton: boolean;
  startDate: Date | null;
  updatePropertiesDone: boolean;
};

const initialState = {
  endDate: null,
  filterSettings: {},
  latestQueryParams: null,
  mapRef: null,
  properties: [],
  property: null,
  propertyCount: 0,
  selectedProperties: [],
  showRefreshButton: false,
  startDate: null,
  updatePropertiesDone: false,
};

// action types
export const UPDATE_PROPERTY = 'hafh/map/UPDATE_PROPERTY' as const;

export const UPDATE_PROPERTIES = 'hafh/map/UPDATE_PROPERTIES' as const;

export const UPDATE_SELECTED_PROPERTIES =
 'hafh/map/UPDATE_SELECTED_PROPERTIES' as const;

export const UPDATE_PROPERTY_COUNT = 'hafh/map/UPDATE_PROPERTY_COUNT' as const;

export const UPDATE_MIN_COINS = 'hafh/map/UPDATE_MIN_COINS' as const;

export const UPDATE_MAX_COINS = 'hafh/map/UPDATE_MAX_COINS' as const;

export const UPDATE_HISTOGRAM_DATA = 'hafh/map/UPDATE_HISTOGRAM_DATA' as const;

export const SET_UPDATE_PROPERTIES_DONE =
  'hafh/map/SET_UPDATE_PROPERTIES_DONE' as const;

export const SET_FILTER_SETTINGS = 'hafh/map/SET_FILTER_SETTINGS' as const;

export const SET_SHOW_REFRESH_BUTTON =
  'hafh/map/SET_SHOW_REFRESH_BUTTON' as const;

export const SET_MAP_REF = 'hafh/map/SET_MAP_REF' as const;

export const SET_QUERY_PARAMS = 'hafh/map/SET_QUERY_PARAMS' as const;

// reducers
const SearchMap = (
  state: SearchMapState = initialState,
  action: ReturnType<
    | typeof setFilterSettings
    | typeof setMapRef
    | typeof setQueryParams
    | typeof setShowRefreshButton
    | typeof setUpdatePropertiesDone
    | typeof updateHistogramData
    | typeof updateMaxCoins
    | typeof updateMinCoins
    | typeof updateProperties
    | typeof updateProperty
    | typeof updatePropertyCount
    | typeof updateSelectedProperties
  >
) => {
  if (action.type === UPDATE_PROPERTY) {
    return {
      ...state,
      property: action.payload,
    };
  }

  if (action.type === UPDATE_PROPERTIES) {
    return {
      ...state,
      properties: action.payload,
    };
  }

  if (action.type === UPDATE_SELECTED_PROPERTIES) {
    return {
      ...state,
      selectedProperties: action.payload,
    };
  }

  if (action.type === UPDATE_PROPERTY_COUNT) {
    return {
      ...state,
      propertyCount: action.payload,
    };
  }

  if (action.type === UPDATE_MIN_COINS) {
    return {
      ...state,
      minCoins: action.payload,
    };
  }

  if (action.type === UPDATE_MAX_COINS) {
    return {
      ...state,
      maxCoins: action.payload,
    };
  }

  if (action.type === UPDATE_HISTOGRAM_DATA) {
    return {
      ...state,
      histogramData: action.payload,
    };
  }

  if (action.type === SET_UPDATE_PROPERTIES_DONE) {
    return {
      ...state,
      updatePropertiesDone: action.payload,
    };
  }

  if (action.type === SET_FILTER_SETTINGS) {
    return {
      ...state,
      filterSettings: action.payload,
    };
  }

  if (action.type === SET_SHOW_REFRESH_BUTTON) {
    return {
      ...state,
      showRefreshButton: action.payload,
    };
  }

  if (action.type === SET_MAP_REF) {
    return {
      ...state,
      mapRef: action.payload,
    };
  }

  if (action.type === SET_QUERY_PARAMS) {
    return {
      ...state,
      latestQueryParams: action.payload,
    };
  }

  return state;
};

// actions creators
export const updateProperty = (property: null | Property) => ({
  payload: property,
  type: UPDATE_PROPERTY,
});

export const updateProperties = (properties: Property[] = []) => ({
  payload: properties,
  type: UPDATE_PROPERTIES,
});

export const updateSelectedProperties = (properties: Property[] = []) => ({
  payload: properties,
  type: UPDATE_SELECTED_PROPERTIES,
});

export const updatePropertyCount = (propertyCount: number) => ({
  payload: propertyCount,
  type: UPDATE_PROPERTY_COUNT,
});

export const updateMinCoins = (minCoins: number) => ({
  payload: minCoins,
  type: UPDATE_MIN_COINS,
});

export const updateMaxCoins = (maxCoins: number) => ({
  payload: maxCoins,
  type: UPDATE_MAX_COINS,
});

export const updateHistogramData = (histogram: number[]) => ({
  payload: histogram,
  type: UPDATE_HISTOGRAM_DATA,
});

export const setUpdatePropertiesDone = (updatePropertiesDone: boolean) => ({
  payload: updatePropertiesDone,
  type: SET_UPDATE_PROPERTIES_DONE,
});

export const setFilterSettings = (filterSettings: object) => ({
  payload: filterSettings,
  type: SET_FILTER_SETTINGS,
});

export const setShowRefreshButton = (showRefreshButton: boolean) => ({
  payload: showRefreshButton,
  type: SET_SHOW_REFRESH_BUTTON,
});

export const setMapRef = (mapRef: { map: any; maps: any }) => ({
  payload: mapRef,
  type: SET_MAP_REF,
});

export const setQueryParams = (params: ParsedUrlQuery) => ({
  payload: params,
  type: SET_QUERY_PARAMS,
});

// actions
export const getProperties =
  (locale: LANG_LOCALE, v2: boolean, filters = {}, authUser?: User) =>
  async (dispatch: Dispatch<Action>) => {
    const path = v2 ? 'properties/search_v2' : 'properties';
    const data = await api.getV2(path, locale, {
      authUser,
      // Needs to pass hide the common error toast
      handleError: () => {},
      params: filters,
    });


    if (v2) {
      const properties = data.properties;
      dispatch(updateProperties(properties));
      dispatch(updatePropertyCount(data.properties_count));
      return properties;
    }

    const properties = data || [];

    dispatch(updatePropertyCount(properties.length));
    dispatch(updateProperties(properties));
    const propertiesWithLatLong =
      properties &&
      properties.filter(
        (property: Property) =>
          property.latitude && property.longitude && property.thumbnail_url
      );

    return propertiesWithLatLong;
  };

export const getPropertyFilters =
  (locale: LANG_LOCALE, v2: boolean, filters = {}, authUser?: User) =>
  async (dispatch: Dispatch<Action>) => {
    const path = v2 ? 'properties/filters_v2' : 'properties/filters';
    const data = await api.getV2(path, locale, {
      authUser,
      // Needs to pass hide the common error toast
      handleError: () => {},
      params: filters,
    });

    dispatch(setFilterSettings(data));

    if (v2) {
      dispatch(updateMinCoins(data.min_coins));
      dispatch(updateMaxCoins(data.max_coins));
      dispatch(updateHistogramData(data.histogram));
      return data;
    }

    dispatch(updateMinCoins(data.coins.min));
    dispatch(updateMaxCoins(data.coins.max));

    return data;
  };

export const showProperties =
  (
    properties: Property[] = [],
    mapRef: { map: any; maps: any } | null = null,
    boundingBox = null,
    zoomToProperties = true
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(setUpdatePropertiesDone(true));

    if (zoomToProperties) {
      if (mapRef && mapRef.map && mapRef.maps) {
        if (properties.length === 0) {
          if (boundingBox) {
            fitMapToArea(mapRef, null, boundingBox);
          }
        } else {
          fitBounds(mapRef.map, mapRef.maps, properties);
        }
      }
    }

    if (properties.length === 0) {
      dispatch(setShowRefreshButton(false));
    }

    return properties;
  };

export default SearchMap;

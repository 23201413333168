// browser
export const isBrowser = () => {
  if (typeof window === 'undefined') {
    return false;
  } else {
    return true;
  }
};

// Mobile
export const isMobile = () => {
  if (isAndroid() || isiOS()) {
    return true;
  } else {
    return false;
  }
};

const isiOS = isBrowser()
  ? () => {
      if (
        (/iPad|iPhone|iPod/i.test(
          navigator.userAgent || navigator.vendor || window.opera
        ) &&
          !window.MSStream) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
      ) {
        return true;
      } else {
        return false;
      }
    }
  : () => {
      return false;
    };

const isAndroid = isBrowser()
  ? () => {
      if (
        /android/i.test(
          navigator.userAgent || navigator.vendor || window.opera
        ) &&
        !window.MSStream
      ) {
        return true;
      } else {
        return false;
      }
    }
  : () => {
      return false;
    };

import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .toast-body {
    display: flex;
    align-items: center;

    &--error {
      color: ${COLORS.red600};
    }

    &__icon {
      margin-right: 8px;
    }

    &__label {
      flex: 1;
      font-size: 14px;
      color: ${COLORS.white};
    }

    &--notify .toast-body__label {
      color: ${COLORS.red700};
    }
  }
`;

export default styles;

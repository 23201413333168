import * as Sentry from '@sentry/nextjs';

const ErrorReporting = {
  captureException(error) {
    Sentry.captureException(error);
  },

  setUser(neighbor) {
    const { email, id } = neighbor;
    Sentry.setUser({ email, id });
  },

  unsetUser() {
    Sentry.configureScope((scope) => scope.setUser(null));
  },
};

export default ErrorReporting;

import { User } from 'firebase/auth';
import { Action, Dispatch } from 'redux';
import { updateCheckReservable } from '@redux/modules/roomTypes';
import apiClient, { API_TYPES } from '@services/hafh/api';
import {
  PropertyDetail,
  RoomTypeRatesMap,
  RoomTypes,
} from '@services/hafh/types/generated';
import { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

const api = apiClient(API_TYPES.API);

const initialState: {
  isLoadingRoomTypeRates: boolean;
  property: any;
  propertyDetail: null | PropertyDetail;
  roomTypeRates: object | RoomTypeRatesMap;
  roomTypes: RoomTypes;
} = {
  isLoadingRoomTypeRates: true,
  property: null,
  propertyDetail: null,
  roomTypeRates: {},
  roomTypes: [],
};

// action types
export const UPDATE_PROPERTY = 'hafh/property/UPDATE_PROPERTY' as const;

export const UPDATE_PROPERTY_DETAIL =
  'hafh/property/UPDATE_PROPERTY_DETAIL' as const;

export const UPDATE_PROPERTY_ROOM_TYPES =
  'hafh/property/UPDATE_PROPERTY_ROOM_TYPES' as const;

export const UPDATE_PROPERTY_ROOM_TYPE_RATES =
  'hafh/property/UPDATE_PROPERTY_ROOM_TYPE_RATES' as const;

export const UPDATE_IS_LOADING_PROPERTY_ROOM_TYPE_RATES =
  'hafh/property/UPDATE_IS_LOADING_PROPERTY_ROOM_TYPE_RATES' as const;

// reducers
const Property = (
  state = initialState,
  action:
    | ReturnType<typeof updateCheckReservable>
    | ReturnType<typeof updateIsLoadingPropertyRoomTypeRates>
    | ReturnType<typeof updateProperty>
    | ReturnType<typeof updatePropertyDetail>
    | ReturnType<typeof updateRoomTypeRates>
    | ReturnType<typeof updateRoomTypes>
) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_IS_LOADING_PROPERTY_ROOM_TYPE_RATES: {
      return {
        ...state,
        isLoadingRoomTypeRates: payload,
      };
    }

    case UPDATE_PROPERTY: {
      return {
        ...state,
        property: {
          ...payload,
          nearby: [payload, payload, payload],
        },
      };
    }

    case UPDATE_PROPERTY_DETAIL: {
      return {
        ...state,
        propertyDetail: payload,
      };
    }

    case UPDATE_PROPERTY_ROOM_TYPE_RATES: {
      return {
        ...state,
        roomTypeRates: payload,
      };
    }

    case UPDATE_PROPERTY_ROOM_TYPES: {
      return {
        ...state,
        roomTypes: payload,
      };
    }

    default: {
      return state;
    }
  }
};

// actions creators
export const updateProperty = (property: any) => ({
  payload: property,
  type: UPDATE_PROPERTY,
});

export const updatePropertyDetail = (propertyDetail: PropertyDetail) => ({
  payload: propertyDetail,
  type: UPDATE_PROPERTY_DETAIL,
});

export const updateRoomTypes = (roomTypes: RoomTypes) => ({
  payload: roomTypes,
  type: UPDATE_PROPERTY_ROOM_TYPES,
});

export const updateRoomTypeRates = (roomTypeRates: RoomTypeRatesMap) => ({
  payload: roomTypeRates,
  type: UPDATE_PROPERTY_ROOM_TYPE_RATES,
});

export const updateIsLoadingPropertyRoomTypeRates = (
  isLoadingRoomTypeRates: boolean
) => ({
  payload: isLoadingRoomTypeRates,
  type: UPDATE_IS_LOADING_PROPERTY_ROOM_TYPE_RATES,
});

// actions
export const getProperty =
  (id: number, locale: LANG_LOCALE, authUser?: User, params = {}) =>
  async (dispatch: Dispatch<Action>) => {
    const path = `properties/${id}/property_room_types`;

    const property = await api.get(
      path,
      params,
      locale,
      authUser && !isEmpty(authUser) ? authUser : null
    );

    if (property) {
      dispatch(updateProperty(property));
    }

    return property;
  };

export const getPropertyDetail =
  (id: number, locale: LANG_LOCALE, authUser?: User) =>
  async (dispatch: Dispatch<Action>) => {
    const propertyDetail: PropertyDetail = await api.get(
      `properties/${id}/`,
      {},
      locale,
      authUser
    );

    if (propertyDetail) {
      dispatch(updatePropertyDetail(propertyDetail));
    }

    return propertyDetail;
  };

export const getPropertyRoomTypes =
  (id: number, locale: LANG_LOCALE, authUser?: User) =>
  async (dispatch: Dispatch<Action>) => {
    const roomTypes = await api.get(
      `properties/${id}/room_types`,
      {},
      locale,
      authUser
    );

    if (roomTypes) {
      dispatch(updateRoomTypes(roomTypes));
    }

    return roomTypes;
  };

export const getRoomTypeRates =
  (
    id: number,
    params: {
      checkInDate?: string;
      checkOutDate?: string;
      guestNumber?: number;
    } = {},
    locale: LANG_LOCALE,
    authUser?: User
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateIsLoadingPropertyRoomTypeRates(true));
    const roomTypeRates = await api.get(
      `properties/${id}/room_types/rates`,
      {
        check_in_date: params.checkInDate,
        check_out_date: params.checkOutDate,
        guest_number: params.guestNumber,
      },
      locale,
      authUser
    );

    if (roomTypeRates) {
      dispatch(updateRoomTypeRates(roomTypeRates));
    }

    dispatch(updateIsLoadingPropertyRoomTypeRates(false));
    return roomTypeRates;
  };

export default Property;

import apiClient, { API_TYPES } from '@services/hafh/api';
import { LANGUAGE_CODES } from '@utils/constants';

const api = apiClient(API_TYPES.WP);

const initialState = {
  current: null,
  experienceTopics: [],
  topics: [],
  total: 0,
};

// action types
export const UPDATE_TOPICS = 'hafh/topic/UPDATE_TOPICS';
export const UPDATE_CURRENT = 'hafh/topic/UPDATE_CURRENT';

// reducers
const Topic = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case UPDATE_TOPICS:
      return {
        ...state,
        topics: action.payload.data,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

const validKeys = ['id', 'date', 'title', 'excerpt', '_embedded'];

// actions creators
export const updateTopics = (topics = []) => {
  const filteredTopics = topics.data.map((t) => {
    const topic = { ...t };
    Object.keys(topic).forEach(
      (key) => validKeys.includes(key) || delete topic[key]
    );
    return topic;
  });
  return {
    payload: {
      ...topics,
      data: filteredTopics,
    },
    type: UPDATE_TOPICS,
  };
};

export const updateCurrentTopic = (topic) => ({
  payload: topic,
  type: UPDATE_CURRENT,
});

// actions
export const getTopics =
  (lang, page = 1, perPage = 9, categories = []) =>
  async (dispatch) => {
    const url = 'posts?_embed';
    const langKey = lang === LANGUAGE_CODES.taiwanese ? 'zh' : lang;
    const topics = await api.get(url, {
      categories,
      lang: langKey,
      page,
      per_page: perPage,
    });

    if (topics) {
      dispatch(updateTopics(topics));
    }
  };

export const getTopic = (id) => async (dispatch) => {
  const url = `posts/${id}?_embed`;
  const topic = await api.get(url);

  if (topic) {
    dispatch(updateCurrentTopic(topic));
  }
};

export default Topic;

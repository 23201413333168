import apiClient, { API_TYPES } from '@services/hafh/api';
import { FlightReservation } from '@utils/types';
import { ValidateReservationParams } from '@utils/types/request';

const api = apiClient(API_TYPES.API);

const defaultFlightReservationsObj = {
  flight_reservations: [],
  total_count: 0,
};

const initialState = {
  flightReservation: null,
  past: defaultFlightReservationsObj,
  upcoming: defaultFlightReservationsObj,
  validationResult: null,
  voucher: {},
};

// action types
export const SET_UPCOMING_FLIGHT_RESERVATIONS =
  'hafh/flightReservations/SET_UPCOMING_FLIGHT_RESERVATIONS';
export const SET_PAST_FLIGHT_RESERVATIONS =
  'hafh/flightReservations/SET_PAST_FLIGHT_RESERVATIONS';
export const SET_FLIGHT_RESERVATION =
  'hafh/flightReservations/SET_FLIGHT_RESERVATION';
export const SET_VALIDATE_RESERVATION =
  'hafh/flightReservations/SET_VALIDATE_RESERVATION';

// reducers
const FlightReservations = (
  state = initialState,
  action: { payload: any; type: string }
) => {
  switch (action.type) {
    case SET_FLIGHT_RESERVATION:
      return {
        ...state,
        flightReservation: action.payload,
      };
    case SET_PAST_FLIGHT_RESERVATIONS:
      return {
        ...state,
        past: action.payload,
      };
    case SET_UPCOMING_FLIGHT_RESERVATIONS:
      return {
        ...state,
        upcoming: action.payload,
      };
    case SET_VALIDATE_RESERVATION:
      return {
        ...state,
        validationResult: action.payload,
      };
    default:
      return state;
  }
};

// actions creators
export const setUpcomingFlightReservations = (
  flightReservations = defaultFlightReservationsObj
) => ({
  payload: flightReservations,
  type: SET_UPCOMING_FLIGHT_RESERVATIONS,
});

export const setPastFlightReservations = (
  flightReservations = defaultFlightReservationsObj
) => ({
  payload: flightReservations,
  type: SET_PAST_FLIGHT_RESERVATIONS,
});

export const setFlightReservation = (flightReservation: FlightReservation) => ({
  payload: flightReservation,
  type: SET_FLIGHT_RESERVATION,
});

export const setValidateReservation = (validationResult: { code: string }) => ({
  payload: validationResult,
  type: SET_VALIDATE_RESERVATION,
});

// actions
export const getUpcomingFlightReservations =
  (locale: string, authUser: any) => async (dispatch) => {
    const flightReservations = await api.get(
      'neighbors/flight_reservations',
      { status: 'upcoming' },
      locale,
      authUser
    );

    if (flightReservations) {
      dispatch(setUpcomingFlightReservations(flightReservations));
    }
  };

export const getPastFlightReservations =
  (locale: string, authUser: any) => async (dispatch) => {
    const flightReservations = await api.get(
      'neighbors/flight_reservations',
      { status: 'past' },
      locale,
      authUser
    );

    if (flightReservations) {
      dispatch(setPastFlightReservations(flightReservations));
    }
  };

export const createFlightReservation =
  (postData, locale, authUser) => async (dispatch) => {
    const flightReservation = await api.post(
      'neighbors/flight_reservations',
      postData,
      locale,
      authUser
    );

    if (flightReservation) {
      dispatch(setFlightReservation(flightReservation));
    }

    return flightReservation;
  };

export const cancelFlightReservation =
  (flightReservationId: number, locale: string, authUser: any) =>
  async (dispatch) => {
    const flightReservation = await api.post(
      `neighbors/flight_reservations/${flightReservationId}/cancel`,
      {},
      locale,
      authUser
    );

    if (flightReservation) {
      dispatch(setFlightReservation(flightReservation));
    }

    return flightReservation;
  };

export const validateFlightReservation =
  (params: ValidateReservationParams, locale: string, authUser: any) =>
  async (dispatch) => {
    const res = await api.get(
      'neighbors/flight_reservations/validate',
      params,
      locale,
      authUser
    );
    if (res) {
      dispatch(setValidateReservation(res));
    }
    return res;
  };

export default FlightReservations;

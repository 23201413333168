import { User } from 'firebase/auth';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { InvitationCampaign } from '@services/hafh/types/generated';
import { LANG_LOCALE } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState = null;

const SET_REFERRAL_CAMPAIGN = 'hafh/campaign/SET_CAMPAIGN';

type CampaignAction = {
  payload: InvitationCampaign;
  type: typeof SET_REFERRAL_CAMPAIGN;
};

const referralCampaignReducers = (
  state: InvitationCampaign | null = initialState,
  action: CampaignAction
) => {
  if (action.type === SET_REFERRAL_CAMPAIGN) {
    return { ...state, ...action.payload };
  }

  return state;
};

export const setReferralCampaign = (campaign: InvitationCampaign) => ({
  payload: campaign,
  type: SET_REFERRAL_CAMPAIGN,
});

export const getReferralCampaign =
  (authUser: User, locale: LANG_LOCALE, ipAddress?: null | string | string[]) =>
  async (dispatch: any) => {
    const clientIpAddress =
      process.env.NEXT_PUBLIC_CLIENT_IP || ipAddress || '';
    const campaign = await api.get(
      'invitation_campaigns',
      { clientIpAddress },
      locale,
      authUser
    );

    dispatch(setReferralCampaign(campaign));
  };

export default referralCampaignReducers;

import { useMemo } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  isLocationGlobal,
  isLocationJapan,
  isLocationKorea,
  isLocationTaiwan,
} from '@utils/utils';

const useLocation = () => {
  const { location } = useAppSelector((state) => ({
    location: state.location,
  }));

  return useMemo(
    () => ({
      isGlobal: isLocationGlobal(location),
      isJapan: isLocationJapan(location),
      isKorea: isLocationKorea(location),
      isTaiwan: isLocationTaiwan(location),
      location,
    }),
    [location?.country?.id]
  );
};

export default useLocation;

import apiClient, { API_TYPES } from '@services/hafh/api';
import { coinTabRanges } from '@utils/constants';

const api = apiClient(API_TYPES.API);

const initialState = [];

export const UPDATE_WEEKLY_PROPERTY_RANKING =
  'hafh/weeklyPropertyRanking/UPDATE_WEEKLY_PROPERTY_RANKING';

const WeeklyPropertyRanking = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_WEEKLY_PROPERTY_RANKING:
      return [...action.payload];
    default:
      return state;
  }
};

export const updateWeeklyPropertyRanking = (weeklyPropertyRanking = []) => ({
  payload: weeklyPropertyRanking,
  type: UPDATE_WEEKLY_PROPERTY_RANKING,
});

export const getWeeklyPropertyRanking =
  (locale, coinRangeKey = Object.keys(coinTabRanges)[0]) =>
  async (dispatch) => {
    const minCoin = coinTabRanges[coinRangeKey].min;
    const maxCoin = coinTabRanges[coinRangeKey].max;
    const url = `properties/rankings/weekly_reservation_count?minCoin=${minCoin}${
      maxCoin ? `&maxCoin=${maxCoin}` : ''
    }`;
    const weeklyPropertyRanking = await api.get(url, {}, locale);

    if (weeklyPropertyRanking) {
      dispatch(updateWeeklyPropertyRanking(weeklyPropertyRanking));
      return weeklyPropertyRanking;
    }
  };

export default WeeklyPropertyRanking;

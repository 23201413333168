import apiClient, { API_TYPES } from '@services/hafh/api';

export const SET_LEGAL_DATA = 'hafh/LEGAL/SET_REGAL_DATA';

const api = apiClient(API_TYPES.API);

const initialState = {};
const Legal = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEGAL_DATA:
      return action.payload;
    default:
      return state;
  }
};
export const getLegal =
  (locale: string, pageName: string, authUser = null) =>
  async (dispatch) => {
    const res = await api.get(`legal/${pageName}`, {}, locale, authUser);

    if (res) {
      dispatch({
        payload: {
          [pageName]: res,
        },
        type: SET_LEGAL_DATA,
      });
    }
  };

export default Legal;

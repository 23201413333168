import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .typo {
    color: ${COLORS.black900};
    white-space: pre-wrap;
  }
  .line-through {
    text-decoration: line-through;
  }
  .underline {
    text-decoration: underline;
  }
  .italic {
    font-style: italic;
  }
  .typo-xxxs {
    font-size: 10px;
    line-height: 14px;
  }
  .typo-xxs {
    font-size: 12px;
    line-height: 18px;
  }
  .typo-xs {
    font-size: 14px;
    line-height: 20px;
  }
  .typo-s {
    font-size: 16px;
    line-height: 24px;
  }
  .typo-m {
    font-size: 20px;
    line-height: 30px;
  }
  .typo-l {
    font-size: 24px;
    line-height: 36px;
  }
  .typo-xl {
    font-size: 28px;
    line-height: 42px;
  }
  .typo-xxl {
    font-size: 32px;
    line-height: 48px;
  }
  .typo-xxxl {
    font-size: 36px;
    line-height: 52px;
  }
  .typo-xxxxl {
    font-size: 40px;
    line-height: 58px;
  }
  .typo-xxxxxxl {
    font-size: 48px;
    line-height: 70px;
  }
  .typo-bold {
    font-weight: 700;
  }
`;

export default styles;

import { Neighbor } from '@services/hafh/types/generated';
import { isBrowser } from './browser';

type WindowWithDataLayer = {
  dataLayer: Record<string, any>[];
} & Window;

declare const window: WindowWithDataLayer;

export const pushDataLayer = (dataLayerObject: {
  [key: string]: { [key: string]: string } | number | string;
}) => {
  if (!isBrowser() || !window.dataLayer) {
    return;
  }
  (window as WindowWithDataLayer).dataLayer.push(dataLayerObject);
};

export const sendVirtualPageView = ({
  neighbor,
  title,
  url,
}: {
  neighbor: Neighbor;
  title: string;
  url: string;
}) => {
  pushDataLayer({
    event: 'virtual_page_view',
    page: {
      spa_title: title,
      spa_url: url,
    },
    ...(neighbor?.id
      ? {
          neighbor_id: neighbor.id,
        }
      : {}),
  });
};

import apiClient, { API_TYPES } from '@services/hafh/api';
import toast from '@utils/toast';

const api = apiClient(API_TYPES.API);

const initialState = {};

// action types
export const UPDATE_PASSPORT = 'hafh/neighbor/UPDATE_PASSPORT';

// reducers
const passport = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSPORT:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

// actions
export const createPassportImage =
  (data, authUser, locale, callback) => async (dispatch) => {
    const res = await api.post('neighbor_passport', data, locale, authUser);

    if (res) {
      toast.success('success.successUpload', true);
      dispatch({
        payload: res,
        type: UPDATE_PASSPORT,
      });
      callback();
    }
  };

export const updatePassportImage =
  (data, authUser, locale, callback) => async (dispatch) => {
    const res = await api.put('neighbor_passport', data, locale, authUser);

    if (res) {
      toast.success('success.successUpload', true);
      dispatch({
        payload: res,
        type: UPDATE_PASSPORT,
      });
      callback();
    }
  };

export default passport;

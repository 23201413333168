import { User } from 'firebase/auth';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { LANG_LOCALE } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState = {
  globalPromotionProperties: {
    others: [],
    taipei: [],
  },
  properties: [],
};

// action types
export const UPDATE_LIST = 'hafh/property/UPDATE_LIST' as const;

export const UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST =
  'hafh/property/UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST' as const;

// reducers
const PropertyLists = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST: {
      return {
        ...state,
        globalPromotionProperties: {
          ...state.globalPromotionProperties,
          [payload.selectedAreaKey]: payload.properties,
        },
      };
    }

    case UPDATE_LIST: {
      return {
        ...state,
        properties: payload.properties,
      };
    }

    default: {
      return state;
    }
  }
};

// actions creators
export const updatePropertyList = (properties = [], acceptLanguage) => ({
  payload: {
    acceptLanguage,
    properties,
  },
  type: UPDATE_LIST,
});

export const updateGlobalPromotionPropertyList = (
  properties = [],
  selectedAreaKey: string
) => ({
  payload: {
    properties,
    selectedAreaKey,
  },
  type: UPDATE_GLOBAL_PROMOTION_PROPERTY_LIST,
});

// actions
export const getPropertyList =
  (locale, filters = {}, authUser?) =>
  async (dispatch) => {
    const properties = await api.get('properties', filters, locale, authUser);

    if (properties) {
      dispatch(updatePropertyList(properties, locale));
    }

    return properties;
  };

export const getGlobalPromotionPropertyList =
  (
    selectedAreaKey: string,
    locale: LANG_LOCALE,
    filters = {},
    authUser?: User
  ) =>
  async (dispatch) => {
    const properties = await api.get('properties', filters, locale, authUser);

    if (properties) {
      dispatch(updateGlobalPromotionPropertyList(properties, selectedAreaKey));
    }

    return properties;
  };

export default PropertyLists;

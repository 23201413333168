import apiClient, { API_TYPES } from '@services/hafh/api';
import { DepartureAirport } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState = [];

// action types
export const GET_AIRPORTS = 'hafh/airports/GET_AIRPORTS';

// reducers
const airports = (
  state = initialState,
  action: { payload: DepartureAirport[]; type: string }
) => {
  switch (action.type) {
    case GET_AIRPORTS:
      return action.payload;
    default:
      return state;
  }
};

// actions
export const getAirports = (locale: string) => async (dispatch: any) => {
  const res = await api.get('airports', {}, locale);

  if (res) {
    dispatch({
      payload: res,
      type: GET_AIRPORTS,
    });
  }
};

export default airports;

import { Location } from '@services/hafh/types/generated';
import apiClient, { API_TYPES } from './api';
import type { AxiosAdapter } from 'axios';

const defaultLocation: Location = {
  country: { calling_code: '+81', code: 'jp', id: 1, name: '日本' },
  language: { abbreviation: 'ja', id: 1, name: '日本語' },
};

/**
 * Get location by client ip address.
 * It should not use in browser.
 * @param ipAddress client ip address
 * @param locale client locale
 * @param adapter AxiosAdapter, in Next.js middleware, axios needs it
 */
export const getLocationByIp = async (
  ipAddress: null | string | string[],
  locale = '',
  adapter?: AxiosAdapter
): Promise<Location> => {
  const api = apiClient(API_TYPES.API, adapter);
  const clientIpAddress = process.env.NEXT_PUBLIC_CLIENT_IP || ipAddress;

  if (!clientIpAddress) {
    return defaultLocation;
  }

  return api
    .get('neighbors/location', { clientIpAddress }, locale)
    .then((value: false | Location) => value || defaultLocation)
    .catch((error) => {
      // when failed to fetch location, we use default locale
      // eslint-disable-next-line no-console
      console.error(error);

      return defaultLocation;
    });
};

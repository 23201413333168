import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = {
  newsItem: null,
  newsItems: [],
};

// action types
const SET_NEWS_ITEMS = 'hafh/message/SET_NEWS_ITEMS';
const SET_NEWS_ITEM = 'hafh/message/SET_NEWS_ITEM';

// reducers
const NewsItem = (state = initialState, action) => {
  switch (action.type) {
    case SET_NEWS_ITEM:
      return {
        ...state,
        newsItem: action.payload,
      };
    case SET_NEWS_ITEMS:
      return {
        ...state,
        newsItems: action.payload,
      };
    default:
      return state;
  }
};

export function setNewsItem(newsItem) {
  return {
    payload: newsItem,
    type: SET_NEWS_ITEM,
  };
}

// actions creators
export function setNewsItems(newsItems) {
  return {
    payload: newsItems,
    type: SET_NEWS_ITEMS,
  };
}

// actions
export const getNewsItems = (authUser, locale) => async (dispatch) => {
  const newsItems = await api.get('news_items', {}, locale, authUser);
  if (newsItems) {
    dispatch(setNewsItems(newsItems));
  }
  return newsItems;
};

export const getNewsItem =
  (newsItemId, authUser, locale) => async (dispatch) => {
    const newsItem = await api.get(
      `news_items/${newsItemId}`,
      {},
      locale,
      authUser
    );
    if (newsItem) {
      dispatch(setNewsItem(newsItem));
    }
  };

export default NewsItem;

import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = {
  notification: null,
  notifications: [],
};

// action types
const SET_NOTIFICATIONS = 'hafh/message/SET_NOTIFICATIONS';
const SET_NOTIFICATION = 'hafh/message/SET_NOTIFICATION';

// reducers
const Notification = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};

export function setNotification(notification) {
  return {
    payload: notification,
    type: SET_NOTIFICATION,
  };
}

// actions creators
export function setNotifications(notifications) {
  return {
    payload: notifications,
    type: SET_NOTIFICATIONS,
  };
}

// actions
export const getNotifications = (authUser, locale) => async (dispatch) => {
  const notifications = await api.get('notifications', {}, locale, authUser);
  if (notifications) {
    dispatch(setNotifications(notifications));
  }
  return notifications;
};

export const getNotification =
  (notificationId, authUser, locale) => async (dispatch) => {
    const notification = await api.get(
      `notifications/${notificationId}`,
      {},
      locale,
      authUser
    );
    if (notification) {
      dispatch(setNotification(notification));
    }
  };

export default Notification;

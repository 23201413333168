import { User } from 'firebase/auth';
import { Moment } from 'moment-timezone';
import { Action, Dispatch } from 'redux';
import apiClient, { API_TYPES } from '@services/hafh/api';
import {
  RoomTypePlansCheckReservable,
  RoomTypeVacancyAvailableDatesMap,
} from '@services/hafh/types/generated';
import { LANG_LOCALE } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState: {
  checkReservable: RoomTypePlansCheckReservable;
  isLoadingCheckReservable: boolean;
  vacancy: RoomTypeVacancyAvailableDatesMap;
} = {
  checkReservable: {
    adjustment_coin: null,
    can_use_payment_options: null,
    min_coin_per_night: {
      base: 0,
      vip: null,
    },
    purchase_coin_limit: {
      amount: null,
      coin: null,
      currency: {
        code: '',
        decimal_point: 0,
        symbol: '',
      },
    },
    should_reset_dates: false,
    should_update_vacancy: false,
    total_coin: null,
    vip: {
      discount_rate: 0,
      rank: null,
    },
    vip_total_coin: null,
  },
  isLoadingCheckReservable: true,
  vacancy: {},
};

export const UPDATE_ROOM_TYPE_VACANCY =
  'hafh/roomTypes/UPDATE_ROOM_TYPE_VACANCY' as const;

export const UPDATE_CHECK_RESERVABLE =
  'hafh/roomTypes/UPDATE_CHECK_RESERVABLE' as const;

export const UPDATE_IS_LOADING_CHECK_RESERVABLE =
  'hafh/roomTypes/UPDATE_IS_LOADING_CHECK_RESERVABLE' as const;

const RoomTypes = (
  state = initialState,
  action:
    | ReturnType<typeof updateCheckReservable>
    | ReturnType<typeof updateIsLoadingCheckReservable>
    | ReturnType<typeof updateRoomTypeVacancy>
) => {
  switch (action.type) {
    case UPDATE_CHECK_RESERVABLE: {
      return {
        ...state,
        checkReservable: {
          ...state.checkReservable,
          ...action.payload,
        },
      };
    }

    case UPDATE_IS_LOADING_CHECK_RESERVABLE: {
      return {
        ...state,
        isLoadingCheckReservable: action.payload,
      };
    }

    case UPDATE_ROOM_TYPE_VACANCY: {
      return {
        ...state,
        vacancy: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const updateRoomTypeVacancy = (roomTypeVacancy = {}) => ({
  payload: roomTypeVacancy,
  type: UPDATE_ROOM_TYPE_VACANCY,
});

export const updateCheckReservable = (
  checkReservable: RoomTypePlansCheckReservable
) => ({
  payload: checkReservable,
  type: UPDATE_CHECK_RESERVABLE,
});

export const updateIsLoadingCheckReservable = (
  isLoadingCheckReservable: boolean
) => ({
  payload: isLoadingCheckReservable,
  type: UPDATE_IS_LOADING_CHECK_RESERVABLE,
});

export const getRoomTypeVacancy =
  (roomTypeId: number, guestNumber: number) =>
  async (dispatch: Dispatch<Action>) => {
    const roomTypeVacancy = await api.get(
      `room_types/${roomTypeId}/vacancy`,
      {
        guest_number: guestNumber,
      },
      ''
    );

    if (roomTypeVacancy) {
      dispatch(updateRoomTypeVacancy(roomTypeVacancy));

      return roomTypeVacancy;
    }
  };

export const getCheckReservable =
  (
    {
      checkin,
      checkout,
      guestNumber,
      isSplitBooking,
      roomTypeId,
    }: {
      checkin?: Moment;
      checkout?: Moment;
      guestNumber: number;
      isSplitBooking?: boolean;
      roomTypeId: Array<number>;
    },
    locale: LANG_LOCALE,
    authUser: User
  ) =>
  async (dispatch: Dispatch<Action>) => {
    dispatch(updateIsLoadingCheckReservable(true));
    const checkReservable: RoomTypePlansCheckReservable = await api.get(
      `room_types/${roomTypeId}/check_reservable`,
      {
        check_in_date: checkin,
        check_out_date: checkout,
        guest_number: guestNumber,
        is_split_reservation: isSplitBooking,
      },
      locale,
      authUser
    );

    if (checkReservable) {
      dispatch(updateCheckReservable(checkReservable));
    }

    return dispatch(updateIsLoadingCheckReservable(false));
  };

export default RoomTypes;

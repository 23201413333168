import apiClient, { API_TYPES } from '@services/hafh/api';

type SignupLimitState = {
  limit: null | number;
};

const api = apiClient(API_TYPES.API);

const initialState: SignupLimitState = { limit: null };

// action types
export const GET_SIGNUP_LIMIT = 'hafh/signupLimit/GET_SIGNUP_LIMIT';

// reducers
const signupLimit = (
  state = initialState,
  action: { payload: SignupLimitState; type: typeof GET_SIGNUP_LIMIT }
) => {
  if (action.type === GET_SIGNUP_LIMIT) {
    return action.payload;
  }

  return state;
};

// actions
export const getSignupLimit = (locale: string) => async (dispatch: any) => {
  const res = await api.get('neighbor_signup_limit', {}, locale);

  if (res) {
    dispatch({
      payload: res,
      type: GET_SIGNUP_LIMIT,
    });
  }
};

export default signupLimit;

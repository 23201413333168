import { ReactElement } from 'react';
import useTranslation from 'next-translate/useTranslation';
import IconCloseCircleFill from '@svg-icons/IconCloseCircleFill';
import IconInfo from '@svg-icons/IconInfo';
import styles from './css';

type Props = {
  label: ReactElement | string;
  shouldTranslate?: boolean;
  type: string;
};

const ToastBody = ({ label, shouldTranslate = false, type }: Props) => {
  const { t } = useTranslation('toast');

  return (
    <div className={`toast-body toast-body--${type}`} data-testid="toast">
      {type === 'error' && (
        <div className="toast-body__icon">
          <IconCloseCircleFill />
        </div>
      )}
      {type === 'notify' && (
        <div className="toast-body__icon">
          <IconInfo color="red" />
        </div>
      )}
      <span className="toast-body__label">
        {shouldTranslate ? t(label as string) : label}
      </span>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default ToastBody;

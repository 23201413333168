import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { sendEmailVerification } from 'firebase/auth';
import moment from 'moment';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TypoXSBold } from '@atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import useLocation from '@hooks/useLocation';
import useRegistrationCountry from '@hooks/useRegistrationCountry';
import * as legalActions from '@redux/modules/legal';
import auth from '@services/firebase';
import { LEGAL_PAGES } from '@utils/constants';
import routes, { getUrlWithLocale } from '@utils/routes';
import toast from '@utils/toast';
import { formatDiscountRate, isEmpty, isSupportedLocale } from '@utils/utils';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/en';
import 'moment/locale/ja';
import 'moment/locale/ko';
import 'moment/locale/zh-tw';

export const useActions = (actions: any) => {
  const dispatch = useDispatch();

  return useMemo(
    () => bindActionCreators({ ...actions }, dispatch),
    [actions, dispatch]
  );
};

export const useMomentLocale = (locale?: string) => {
  const safeLocale = locale && isSupportedLocale(locale) ? locale : 'ja';

  useEffect(() => {
    if (safeLocale === moment.locale()) {
      return;
    }
    moment.locale(safeLocale);
    dayjs.locale(safeLocale);
  }, [safeLocale]);
};

export const useDayJsLocale = (locale?: string) => {
  const safeLocale = locale && isSupportedLocale(locale) ? locale : 'ja';

  useEffect(() => {
    if (safeLocale === dayjs.locale()) {
      return;
    }
    dayjs.locale(safeLocale);
  }, [safeLocale]);
};

export const useHandleOutsideClick = (
  targetSelector: string,
  callback: () => void,
  watchTarget: boolean[] = []
) => {
  useEffect(() => {
    if (watchTarget.every((wt) => !wt)) {
      return;
    }

    const handleClick = ({ target }: Event) => {
      const targetElement = document.querySelector(targetSelector);
      const isOutsideClick =
        targetElement &&
        target instanceof Node &&
        !targetElement.contains(target);

      if (isOutsideClick) {
        callback();
      }
    };

    // setTimeout is necessary because handleClick is executed when click event is registered.
    // ex.) If you remove setTimeout, Modal won't be displayed.
    setTimeout(() => {
      document.addEventListener('click', handleClick);
    }, 1);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, watchTarget);
};

export const useDisablePageScrollWhenShowModal = (
  show: boolean,
  returnEarly = false
) => {
  const fixScrollPosition = () => {
    const page = document.querySelector('body');

    if (page) {
      page.style.top = `-${window.scrollY}px`;
      page.style.position = 'fixed';
      page.style.width = '100%';
    }
  };

  const unFixScrollPosition = () => {
    const page = document.querySelector('body');

    if (page) {
      const scrollY = page.style.top;
      page.style.top = '';
      page.style.position = '';
      page.style.width = 'auto';
      window.scrollTo(0, Number.parseInt(scrollY, 10) * -1);
    }
  };

  useEffect(() => {
    if (returnEarly) {
      return;
    }

    const page = document.querySelector('body');

    if (show) {
      fixScrollPosition();
    } else if (page?.style.position === 'fixed') {
      unFixScrollPosition();
    }

    return () => {
      if (page?.style.position === 'fixed') {
        unFixScrollPosition();
      }
    };
  }, [show]);
};

export const useDisplayEmailVerificationToast = () => {
  const router = useRouter();
  const { locale } = router;
  const { authUser, emailVerified, neighbor } = useAppSelector((state) => ({
    authUser: state.authUser,
    emailVerified: state.emailVerified,
    neighbor: state.neighbor,
  }));
  const { t } = useTranslation('verify-email');

  useEffect(() => {
    const onClick = async () => {
      const url = getUrlWithLocale(router, locale, routes.root);

      if (!auth.currentUser) {
        return toast.error('error.default', true);
      }
      await sendEmailVerification(auth.currentUser, { url })
        .then(() => {
          router.push(routes.profileVerifyEmail);
        })
        .catch(() => {
          toast.error(t('resentError'));
          router.push(routes.profileVerifyEmail);
        });
    };

    if (
      !isEmpty(authUser) &&
      !isEmpty(neighbor) &&
      !authUser.emailVerified &&
      !emailVerified
    ) {
      toast.notify(
        <Trans
          components={{
            bold: <TypoXSBold color="red600" />,
            br: <br />,
          }}
          i18nKey="verify-email:toastReminder"
        />,
        onClick
      );
    }
  }, [authUser, locale, neighbor, router]);
};

export const useRecommendedPlan = () => {
  const { neighborPlans } = useAppSelector((state) => ({
    neighborPlans: state.neighborPlans,
  }));

  return neighborPlans?.find((np) => np.recommend);
};

export const useIsDiscount = () => {
  const recommendedPlan = useRecommendedPlan();

  return (
    recommendedPlan &&
    recommendedPlan.first_month_price < recommendedPlan.monthly_price
  );
};

export const useRecommendedPlanDiscountRate = () => {
  const recommendedPlan = useRecommendedPlan();

  return formatDiscountRate(recommendedPlan?.discount?.rate);
};

export const useRecommendedPlanFirstMonthPrice = () => {
  const recommendedPlan = useRecommendedPlan();

  return recommendedPlan?.first_month_price?.toLocaleString();
};

export const useLegalPageData = (legalPageName: LEGAL_PAGES) => {
  const { locale } = useRouter();

  const { authUser, legal, loadingAuthUser } = useAppSelector((state) => ({
    authUser: state.authUser,
    legal: state.legal,
    loadingAuthUser: state.loadingAuthUser,
  }));

  const { getLegal } = useActions({
    ...legalActions,
  });

  useEffect(
    function updateBanner() {
      if (!loadingAuthUser) {
        getLegal(locale, legalPageName, authUser);
      }
    },
    [loadingAuthUser, locale]
  );

  return legal[legalPageName];
};

export const useIsJapanUser = () => {
  const { loadingNeighbor, neighbor } = useAppSelector((state) => ({
    loadingNeighbor: state.loadingNeighbor,
    neighbor: state.neighbor,
  }));
  const { isJapan } = useLocation();
  const { isRegistrationCountryJapan } = useRegistrationCountry();

  if (loadingNeighbor) {
    return false;
  }

  return isRegistrationCountryJapan || (isEmpty(neighbor) && isJapan);
};

export const useIsKoreaUser = () => {
  const { loadingNeighbor, neighbor } = useAppSelector((state) => ({
    loadingNeighbor: state.loadingNeighbor,
    neighbor: state.neighbor,
  }));
  const { isKorea } = useLocation();
  const { isRegistrationCountryKorea } = useRegistrationCountry();

  if (loadingNeighbor) {
    return false;
  }

  return isRegistrationCountryKorea || (isEmpty(neighbor) && isKorea);
};

export const useIsTaiwanUser = () => {
  const { loadingNeighbor, neighbor } = useAppSelector((state) => ({
    loadingNeighbor: state.loadingNeighbor,
    neighbor: state.neighbor,
  }));
  const { isTaiwan } = useLocation();
  const { isRegistrationCountryTaiwan } = useRegistrationCountry();

  if (loadingNeighbor) {
    return false;
  }

  return isRegistrationCountryTaiwan || (isEmpty(neighbor) && isTaiwan);
};

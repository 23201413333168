import * as jsondiffpatch from 'jsondiffpatch';
import { HYDRATE } from 'next-redux-wrapper';
import { AnyAction, combineReducers } from 'redux';
import airports from '@redux/modules/airports';
import authUser from '@redux/modules/authUser';
import availableFlights from '@redux/modules/availableFlights';
import banners from '@redux/modules/banners';
import campaign from '@redux/modules/campaign';
import contact from '@redux/modules/contact';
import country from '@redux/modules/country';
import driverLicense from '@redux/modules/driverLicense';
import emailVerified from '@redux/modules/emailVerified';
import favoriteProperties from '@redux/modules/favoriteProperties';
import featuredProperties from '@redux/modules/featuredProperties';
import flightDepartureDates from '@redux/modules/flightDepartureDates';
import flightProfile from '@redux/modules/flightProfile';
import flightReservations from '@redux/modules/flightReservations';
import flightVouchers from '@redux/modules/flightVouchers';
import flights from '@redux/modules/flights';
import gachaCabinet from '@redux/modules/gachaCabinet';
import gachaLog from '@redux/modules/gachaLog';
import goals from '@redux/modules/goals';
import heroImages from '@redux/modules/heroImages';
import legal from '@redux/modules/legal';
import loader from '@redux/modules/loader';
import loaderAuthUser from '@redux/modules/loaderAuthUser';
import loaderCampaign from '@redux/modules/loaderCampaign';
import loaderLocation from '@redux/modules/loaderLocation';
import loaderNeighbor from '@redux/modules/loaderNeighbor';
import location from '@redux/modules/location';
import message from '@redux/modules/message';
import neighbor from '@redux/modules/neighbor';
import neighborCoinHistory from '@redux/modules/neighborCoinHistory';
import neighborPlans from '@redux/modules/neighborPlans';
import newsItem from '@redux/modules/newsItem';
import notification from '@redux/modules/notification';
import passport from '@redux/modules/passport';
import pendingBonusCoins from '@redux/modules/pendingBonusCoins';
import planIncomeSummary from '@redux/modules/planIncomeSummary';
import property from '@redux/modules/property';
import propertyHistory from '@redux/modules/propertyHistory';
import propertyLists from '@redux/modules/propertyLists';
import purchaseCoin from '@redux/modules/purchaseCoin';
import recommendedProperties from '@redux/modules/recommendedProperties';
import referralCampaign from '@redux/modules/referralCampaign';
import invite from '@redux/modules/referralCode';
import reservationCountRanking from '@redux/modules/reservationCountRanking';
import reservations from '@redux/modules/reservations';
import roomTypes from '@redux/modules/roomTypes';
import search from '@redux/modules/search';
import searchMap from '@redux/modules/searchMap';
import signupLimit from '@redux/modules/signupLimit';
import splitReservations from '@redux/modules/splitReservations';
import tag from '@redux/modules/tag';
import topic from '@redux/modules/topic';
import topicCategories from '@redux/modules/topicCategories';
import travelStories from '@redux/modules/travelStories';
import travelStoriesLists from '@redux/modules/travelStoriesLists';
import unreadCount from '@redux/modules/unreadCount';
import usedCoinsRanking from '@redux/modules/usedCoinsRanking';
import v2FlightReservations from '@redux/modules/v2FlightReservations';
import v2Flights from '@redux/modules/v2Flights';
import weeklyPropertyRanking from '@redux/modules/weeklyPropertyRanking';

const combinedReducer = combineReducers({
  airports,
  authUser,
  availableFlights,
  banners,
  campaign,
  contact,
  country,
  driverLicense,
  emailVerified,
  favoriteProperties,
  featuredProperties,
  flightDepartureDates,
  flightProfile,
  flightReservations,
  flights,
  flightVouchers,
  gachaCabinet,
  gachaLog,
  goals,
  heroImages,
  initialState: (state = null) => state,
  invite,
  legal,
  loader,
  loadingAuthUser: loaderAuthUser,
  loadingCampaign: loaderCampaign,
  loadingLocation: loaderLocation,
  loadingNeighbor: loaderNeighbor,
  location,
  message,
  neighbor,
  neighborCoinHistory,
  neighborPlans,
  newsItem,
  notification,
  passport,
  pendingBonusCoins,
  planIncomeSummary,
  property,
  propertyHistory,
  propertyLists,
  purchaseCoin,
  recommendedProperties,
  referralCampaign,
  reservationCountRanking,
  reservations,
  roomTypes,
  search,
  searchMap,
  signupLimit,
  splitReservations,
  tag,
  topic,
  topicCategories,
  travelStories,
  travelStoriesLists,
  unreadCount,
  usedCoinsRanking,
  v2FlightReservations,
  v2Flights,
  weeklyPropertyRanking,
});

const reducer: typeof combinedReducer = (
  _state: ReturnType<typeof combinedReducer>,
  action: AnyAction
) => {
  const state = {
    ..._state,
    ...(_state && !_state.initialState && { initialState: _state }),
  };

  if (action.type === HYDRATE) {
    const authUserState = state.authUser;
    delete state.authUser; // jsondiffpatch doesn't support object that cointains function
    delete state.initialState.authUser;

    // Client side hydration
    const clientDiff = jsondiffpatch.diff(state.initialState, state);
    const updatedClientState = {};

    if (clientDiff) {
      Object.keys(clientDiff).forEach((clientDiffKey) => {
        if (clientDiffKey !== 'initialState') {
          updatedClientState[clientDiffKey] = state[clientDiffKey];
        }
      });
    }
    // Server side hydration
    const serverDiff = jsondiffpatch.diff(state.initialState, action.payload);
    const updatedServerState = {};

    if (serverDiff) {
      Object.keys(serverDiff).forEach((serverDiffKey) => {
        if (serverDiffKey !== 'initialState') {
          updatedServerState[serverDiffKey] = action.payload[serverDiffKey];
        }
      });
    }

    // Merge states
    return {
      ...state,
      ...action.payload,
      ...updatedClientState,
      ...updatedServerState,
      authUser: authUserState, // authUser is always fetched in client side
    };
  }

  return combinedReducer(state, action);
};

export default reducer;

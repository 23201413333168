import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = {
  properties: [],
};

// action types
export const UPDATE_LIST = 'hafh/propertyHistory/UPDATE_LIST';

// reducers
const PropertyHistory = (state = initialState, action) => {
  const { payload } = action;

  switch (action.type) {
    case UPDATE_LIST: {
      return {
        ...state,
        properties: payload.properties,
      };
    }

    default: {
      return state;
    }
  }
};

// actions creators
export const updatePropertyList = (properties = [], acceptLanguage) => ({
  payload: {
    acceptLanguage,
    properties,
  },
  type: UPDATE_LIST,
});

// actions
export const getPropertyList =
  (locale, filters = {}, authUser?) =>
  async (dispatch) => {
    const data = await api.get('properties', filters, locale, authUser);

    const properties = data;

    if (properties) {
      dispatch(updatePropertyList(properties, locale));
    }
  };

export default PropertyHistory;

import { User } from 'firebase/auth';
import { Action, Dispatch } from 'redux';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { FavoriteProperties as FavoritePropertiesType } from '@services/hafh/types/generated';
import { LANG_LOCALE } from '@utils/types';
import { isEmpty } from '@utils/utils';

const api = apiClient(API_TYPES.API);

const initialState = {
  favorite_properties: [],
  total_counts: 0,
};

export const UPDATE_FAVORITE_PROPERTIES =
  'hafh/favoriteProperties/UPDATE_FAVORITE_PROPERTIES';

const FavoriteProperties = (
  state: FavoritePropertiesType = initialState,
  action: ReturnType<typeof updateFavoriteProperties>
) => {
  if (action.type === UPDATE_FAVORITE_PROPERTIES) {
    return { ...state, ...action.payload };
  }

  return state;
};

export const updateFavoriteProperties = (favoriteProperties = []) => ({
  payload: favoriteProperties,
  type: UPDATE_FAVORITE_PROPERTIES,
});

export const getFavoriteProperties =
  (
    authUser: User,
    locale: LANG_LOCALE,
    pageIndex = 1,
    order = 'popularity_desc'
  ) =>
  async (dispatch: Dispatch<Action>) => {
    if (isEmpty(authUser)) {
      return;
    }

    const favoriteProperties = await api.get(
      'neighbors/favorite_properties',
      {
        order,
        page: pageIndex,
        per: 200,
      },
      locale,
      authUser
    );

    if (favoriteProperties) {
      dispatch(updateFavoriteProperties(favoriteProperties));

      return favoriteProperties;
    }
  };

export const addFavoriteProperty =
  (authUser: User, locale: LANG_LOCALE, propertyId: string) =>
  async (dispatch: Dispatch<Action>) => {
    const favoriteProperties = await api.post(
      'neighbors/favorite_properties',
      {
        per: 200,
        property_id: propertyId,
      },
      locale,
      authUser
    );

    if (favoriteProperties) {
      dispatch(updateFavoriteProperties(favoriteProperties));
    }
  };

export const deleteFavoriteProperty =
  (authUser: User, locale: LANG_LOCALE, propertyId: string) =>
  async (dispatch: Dispatch<Action>) => {
    const favoriteProperties = await api.delete(
      'neighbors/favorite_properties',
      {
        per: 200,
        property_id: propertyId,
      },
      locale,
      authUser
    );

    if (favoriteProperties) {
      dispatch(updateFavoriteProperties(favoriteProperties));
    }
  };

export default FavoriteProperties;

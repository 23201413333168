/* eslint-disable sort-keys */
import { ParsedUrlQuery } from 'node:querystring';
import { Moment } from 'moment-timezone';
import { Property } from '@services/hafh/types/generated';
import { Area, LANG_LOCALE, RoomCategory } from '@utils/types';
import { formatCalendarDate, isEmpty } from '@utils/utils';

export type SearchQueryParams = {
  amenity_tag_ids?: string;
  area_id?: string;
  area_name?: string;
  checkin?: string;
  checkout?: string;
  max_coin?: number;
  min_coin?: number;
  ne_lat?: string;
  ne_lng?: string;
  no_of_guest?: string;
  order?: string;
  page?: number;
  property_type_tag_ids?: string;
  sw_lat?: string;
  sw_lng?: string;
  view?: string;
};

type MapRef = { map: any; maps: any };

type Query =
  | { [key: string]: boolean | number | string | string[] }
  | ParsedUrlQuery;

const getMapBounds = (maps: any, properties: Property[]) => {
  const bounds = new maps.LatLngBounds();

  properties.forEach((property) => {
    bounds.extend(
      new maps.LatLng(Number(property.latitude), Number(property.longitude))
    );
  });

  return bounds;
};

export const fitBounds = (map: any, maps: any, properties: Property[]) => {
  if (!map || !maps || properties.length === 0) {
    return;
  }
  const bounds = getMapBounds(maps, properties);
  map.fitBounds(bounds);

  // zoom out when only one property
  if (properties.length === 1) {
    map.setZoom(map.getZoom() - 7);
  }
};

const boundsFromArea = (area: Area) => {
  if (!area) {
    return null;
  }

  // NOTE: we use viewport not bounds as it's better UX
  return {
    northeast: {
      lat: area.viewport[2],
      lng: area.viewport[3],
    },
    southwest: {
      lat: area.viewport[0],
      lng: area.viewport[1],
    },
  };
};

export const fitMapToArea = (
  mapRef: MapRef,
  area: Area,
  boundingBox: {
    northeast: {
      lat: number;
      lng: number;
    };
    southwest: {
      lat: number;
      lng: number;
    };
  } | null
) => {
  if (!mapRef) {
    return;
  }

  const bounds = boundsFromArea(area) || boundingBox;

  if (isEmpty(bounds) || !bounds) {
    return;
  }

  const { maps } = mapRef;
  const { map } = mapRef;

  const swNew = new maps.LatLng(
    Number(bounds.southwest.lat),
    Number(bounds.southwest.lng)
  );

  const neNew = new maps.LatLng(
    Number(bounds.northeast.lat),
    Number(bounds.northeast.lng)
  );

  const mapBounds = new maps.LatLngBounds(swNew, neNew);

  // call fitBounds with padding 0 so the map doesn't zoom out when we have
  // zero properties returned
  map.fitBounds(mapBounds, 0);

  return mapBounds;
};

const getBoundingBox = (mapRef: MapRef) => {
  if (!mapRef) {
    return;
  }

  return {
    northeast: {
      lat: mapRef.map.getBounds().getNorthEast().lat(),
      lng: mapRef.map.getBounds().getNorthEast().lng(),
    },
    southwest: {
      lat: mapRef.map.getBounds().getSouthWest().lat(),
      lng: mapRef.map.getBounds().getSouthWest().lng(),
    },
  };
};

export const boundsFromQuery = (query: Query) => {
  if (
    !query ||
    !query.sw_lat ||
    !query.sw_lng ||
    !query.ne_lat ||
    !query.ne_lng
  ) {
    return null;
  }

  return {
    northeast: {
      lat: query.ne_lat,
      lng: query.ne_lng,
    },
    southwest: {
      lat: query.sw_lat,
      lng: query.sw_lng,
    },
  };
};

export const boundsQueryFromArea = (area: Area) => {
  if (!area?.viewport) {
    return null;
  }

  return {
    ne_lat: area.viewport[2],
    ne_lng: area.viewport[3],
    sw_lat: area.viewport[0],
    sw_lng: area.viewport[1],
  };
};

export const boundsQueryFromMapRef = (mapRef: MapRef | null) => {
  if (!mapRef) {
    return null;
  }

  const boundingBox = getBoundingBox(mapRef);

  return {
    ne_lat: boundingBox?.northeast.lat,
    ne_lng: boundingBox?.northeast.lng,
    sw_lat: boundingBox?.southwest.lat,
    sw_lng: boundingBox?.southwest.lng,
  };
};

const validRoomTypes = ['private_room', 'dormitory'];

export const roomTypesFromQuery = (roomTypes?: string | string[]) => {
  if (typeof roomTypes === 'string') {
    return roomTypes
      .split(',')
      .filter((rt) => validRoomTypes.includes(rt)) as RoomCategory[];
  }

  if (Array.isArray(roomTypes)) {
    return roomTypes.filter((rt) =>
      validRoomTypes.includes(rt)
    ) as RoomCategory[];
  }

  return [];
};

export const tagIdsFromQuery = (tagIds?: string | string[]): string[] => {
  if (tagIds === '') {
    return [];
  }

  if (typeof tagIds === 'string') {
    return tagIds.split(',').map(String);
  }

  if (Array.isArray(tagIds)) {
    return tagIds;
  }

  return [];
};

export const convertCoinRangeArrayToObject = (
  coinRangeArray: null | number[]
) => {
  if (
    !coinRangeArray ||
    !Array.isArray(coinRangeArray) ||
    coinRangeArray.length !== 2
  ) {
    return null;
  }

  return {
    max_coin: coinRangeArray[1],
    min_coin: coinRangeArray[0],
  };
};

export const coinRangeFromQuery = (query: Query) => {
  if (!query.min_coin || !query.max_coin) {
    return null;
  }

  const minCoin = Number(query.min_coin);
  const maxCoin = Number(query.max_coin);

  if (typeof minCoin !== 'number' || typeof maxCoin !== 'number') {
    return null;
  }

  if (minCoin >= maxCoin) {
    return null;
  }

  return [minCoin, maxCoin];
};

const validOrders = ['popularity_desc', 'coin_asc', 'coin_desc'];

const orderFromQuery = (query: Query) => {
  if (!query.order) {
    return 'total_point_desc';
  }

  if (query.order === 'latest') {
    return '';
  }

  if (typeof query.order === 'string' && validOrders.includes(query.order)) {
    return query.order;
  }

  return 'total_point_desc';
};

export const searchParamsFromQuery = (query: Query) => {
  const coinRangeQuery = coinRangeFromQuery(query);
  const coinRangeObject = convertCoinRangeArrayToObject(coinRangeQuery);

  return {
    amenity_tag_id: tagIdsFromQuery(query.amenity_tag_ids as string),
    area_id: query.area_id,
    check_in_date: query.checkin,
    check_out_date: query.checkout,
    no_of_guest: query.no_of_guest,
    property_id: query.property_id,
    property_type_tag_id: tagIdsFromQuery(
      query.property_type_tag_ids as string
    ),
    room_type: roomTypesFromQuery(query.room_types as string),
    ...coinRangeObject,
    bounding_box: boundsFromQuery(query),
    order: orderFromQuery(query),
    page: query.page,
  };
};

export const searchFormDateText = (
  locale: LANG_LOCALE,
  startDate: Moment,
  endDate: Moment,
  placeHolder: string
) => {
  const startDateString = startDate
    ? formatCalendarDate(startDate, locale)
    : '';
  const endDateString = endDate ? formatCalendarDate(endDate, locale) : '';

  return startDateString && endDateString
    ? `${startDateString} - ${endDateString}`
    : placeHolder;
};

import apiClient, { API_TYPES } from '@services/hafh/api';
import type { Location } from '@services/hafh/types/generated';
import type { PayloadAction } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState: Location = {};

// action types
export const UPDATE_LOCATION = 'hafh/banners/UPDATE_LOCATION';

// reducers
const location = (state = initialState, action: PayloadAction<Location>) => {
  switch (action.type) {
    case UPDATE_LOCATION: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

export const getLocation =
  (locale, callback = null) =>
  async (dispatch) => {
    const location = await api.get('neighbors/location', {}, locale);

    if (location) {
      dispatch(updateLocation(location));

      if (callback) {
        callback();
      }

      return location;
    }
  };

// actions creators
export const updateLocation = (location = initialState) => ({
  payload: location,
  type: UPDATE_LOCATION,
});

export default location;

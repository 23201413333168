import apiClient, { API_TYPES } from '@services/hafh/api';
import { FlightDepartureDate } from '@utils/types';
import { GetFlightDepartureDatesParams } from '@utils/types/request';

const api = apiClient(API_TYPES.API);

const initialState = {
  departure_dates: [],
  required_coin: 0,
  updated_at: null,
};

// action types
export const GET_DEPARTURE_DATES =
  'hafh/flightDepartureDates/GET_DEPARTURE_DATES';

// reducers
const flightDepartureDates = (
  state = initialState,
  action: { payload: FlightDepartureDate; type: string }
) => {
  switch (action.type) {
    case GET_DEPARTURE_DATES:
      return action.payload;
    default:
      return state;
  }
};
// actions
export const getFlightDepartureDates =
  (params: GetFlightDepartureDatesParams, locale: string) =>
  async (dispatch) => {
    const res = await api.get('flights/departure_dates', params, locale);

    if (res) {
      dispatch({
        payload: res,
        type: GET_DEPARTURE_DATES,
      });
    }
  };

export default flightDepartureDates;

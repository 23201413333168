import toast from '@utils/toast';

const ErrorHandler = (error) => {
  const errorMessage = error.response ? error.response.data.error : '';

  if (process.browser) {
    if (errorMessage) {
      toast.error(errorMessage, false);
    } else {
      toast.error('error.default', true);
    }
  } else {
    console.log(error);
  }
};

export default ErrorHandler;

import apiClient, { API_TYPES } from '@services/hafh/api';
import { NeighborPlan } from '@services/hafh/types/generated';
import { PayloadAction } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState: NeighborPlan[] = [];

// action types
export const SET_NEIGHBOR_PLAN = 'hafh/neighbor_plan/SET_NEIGHBOR_PLAN';

// reducer
const neighborPlans = (
  state: NeighborPlan[] = initialState,
  action: PayloadAction<NeighborPlan[]>
) => {
  switch (action.type) {
    case SET_NEIGHBOR_PLAN:
      return action.payload;
    default:
      return state;
  }
};

// actions
export const getNeighborPlans =
  (locale, authUser = null, forSignup = false) =>
  async (dispatch) => {
    const res = await api.get(
      'neighbor_plans',
      {
        ...(forSignup ? { for_signup: 1 } : null),
      },
      locale,
      authUser
    );

    dispatch({
      payload: res,
      type: SET_NEIGHBOR_PLAN,
    });
  };

export default neighborPlans;

import { useMemo } from 'react';
import { COUNTRY_IDS } from '@utils/constants';
import { isEmpty } from '@utils/utils';
import { useAppSelector } from './useAppSelector';

const useRegistrationCountry = () => {
  const { neighbor } = useAppSelector((state) => ({
    neighbor: state.neighbor,
  }));
  const countryId = isEmpty(neighbor)
    ? null
    : neighbor.registration_country?.id;

  return useMemo(
    () => ({
      isRegistrationCountryGlobal: countryId === COUNTRY_IDS.global,
      isRegistrationCountryJapan: countryId === COUNTRY_IDS.japan,
      isRegistrationCountryKorea: countryId === COUNTRY_IDS.korea,
      isRegistrationCountryTaiwan: countryId === COUNTRY_IDS.taiwan,
      registrationCountryCode: neighbor.registration_country?.code || 'jp',
    }),
    [countryId]
  );
};

export default useRegistrationCountry;

import apiClient, { API_TYPES } from '@services/hafh/api';
import type { Country } from '@services/hafh/types/generated';
import type { LANG_LOCALE, PayloadAction } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState: { countries: Array<Country> } = {
  countries: [],
};

// action types
export const SET_COUNTRIES = 'hafh/country/SET_COUNTRIES';

// reducers
const country = (
  state = initialState,
  action: PayloadAction<Array<Country>>
) => {
  switch (action.type) {
    case SET_COUNTRIES: {
      return {
        ...state,
        countries: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

// actions
export const getCountries = (locale: LANG_LOCALE) => async (dispatch) => {
  const res = await api.get('countries', {}, locale);

  if (res) {
    dispatch({
      payload: res,
      type: SET_COUNTRIES,
    });
  }
};

export default country;

import { User } from 'firebase/auth';
import { ThunkAction } from 'redux-thunk';
import apiClient, { API_TYPES } from '@services/hafh/api';
import {
  GachaPlay,
  GachaPlayParams,
  NeighborGachaLog,
} from '@services/hafh/types/generated';

type GachaLogActions = UpdateGachaLogAction;

type GachaLogState = GachaPlay | NeighborGachaLog;

type ThunkResult<R> = ThunkAction<
  R,
  GachaPlay | NeighborGachaLog,
  undefined,
  GachaLogActions
>;

interface UpdateGachaLogAction {
  payload: GachaPlay | NeighborGachaLog;
  type: typeof UPDATE_GACHA_LOG;
}

const api = apiClient(API_TYPES.API);

const initialState: GachaLogState = {} as GachaLogState;

// Action types
export const UPDATE_GACHA_LOG = 'hafh/gachaLog/UPDATE_GACHA_LOG';

// Reducer
const gachaLog = (
  state = initialState,
  action: GachaLogActions
): GachaLogState => {
  switch (action.type) {
    case UPDATE_GACHA_LOG:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

// Action creators
export const updateGachaLog = (
  gachaLog: GachaPlay | NeighborGachaLog
): UpdateGachaLogAction => ({
  payload: gachaLog,
  type: UPDATE_GACHA_LOG,
});

// Thunk action creators
export const getGachaLog =
  (
    gachaLogId: number,
    locale: string,
    authUser: User
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const gachaLog = await api.get(
      `neighbor_gacha_logs/${gachaLogId}`,
      {},
      locale,
      authUser
    );

    if (gachaLog) {
      dispatch(updateGachaLog(gachaLog));
    }
  };

export const postGachaLog =
  (
    gachaCabinetCode: string,
    airportGroupId: number,
    locale: string,
    authUser: User,
    errorCallback?: () => void
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    const params: GachaPlayParams = {
      gacha_airport_group_id: airportGroupId,
    };

    const gachaLog = await api.post(
      `gacha_cabinets/${gachaCabinetCode}`,
      params,
      locale,
      authUser,
      undefined,
      errorCallback
    );

    if (gachaLog) {
      dispatch(updateGachaLog(gachaLog));
    }
  };

export default gachaLog;

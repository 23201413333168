import apiClient, { API_TYPES } from '@services/hafh/api';

const api = apiClient(API_TYPES.API);

const initialState = {
  past: {
    past_months: [],
  },
  upcoming: {
    total_next_three_months_coin: 0,
    upcoming_months: [],
  },
};

export const UPDATE_UPCOMING_PLAN_INCOME_SUMMARY =
  'hafh/goals/UPDATE_UPCOMING_PLAN_INCOME_SUMMARY';
export const UPDATE_PAST_PLAN_INCOME_SUMMARY =
  'hafh/goals/UPDATE_PAST_PLAN_INCOME_SUMMARY';

const planIncomeSummary = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PAST_PLAN_INCOME_SUMMARY:
      return {
        ...state,
        past: action.payload,
      };
    case UPDATE_UPCOMING_PLAN_INCOME_SUMMARY:
      return {
        ...state,
        upcoming: action.payload,
      };
    default:
      return state;
  }
};

export const updateUpcomingPlanIncomeSummary = (planIncomeSummary = {}) => ({
  payload: planIncomeSummary,
  type: UPDATE_UPCOMING_PLAN_INCOME_SUMMARY,
});
export const updatePastPlanIncomeSummary = (planIncomeSummary = {}) => ({
  payload: planIncomeSummary,
  type: UPDATE_PAST_PLAN_INCOME_SUMMARY,
});

export const getUpcomingPlanIncomeSummary =
  (authUser, locale, params = {}) =>
  async (dispatch) => {
    const planIncomeSummary = await api.get(
      'neighbors/plan_income_summary/upcoming',
      params,
      locale,
      authUser
    );

    if (planIncomeSummary) {
      dispatch(updateUpcomingPlanIncomeSummary(planIncomeSummary));
      return planIncomeSummary;
    }
  };

export const getPastPlanIncomeSummary =
  (authUser, locale) => async (dispatch) => {
    const planIncomeSummary = await api.get(
      'neighbors/plan_income_summary/past',
      {},
      locale,
      authUser
    );

    if (planIncomeSummary) {
      dispatch(updatePastPlanIncomeSummary(planIncomeSummary));
      return planIncomeSummary;
    }
  };

export default planIncomeSummary;

import { toast } from 'react-toastify';
import ToastBody from '@atoms/ToastBody';
import ToastClose from '@atoms/ToastClose';
import { BREAKPOINT } from '@utils/constants';

const getPosition = () =>
  window.innerWidth <= BREAKPOINT.mobile ? 'bottom-center' : 'top-center';

const toastUtils = {
  alert: (label: string, shouldTranslate = false) => {
    toast(
      <ToastBody
        label={label}
        shouldTranslate={shouldTranslate}
        type="alert"
      />,
      {
        autoClose: 7000,
        closeButton: <ToastClose />,
        position: getPosition(),
      }
    );
  },
  error: (label: string, shouldTranslate = false) => {
    toast.error(
      <ToastBody
        label={label}
        shouldTranslate={shouldTranslate}
        type="error"
      />,
      {
        autoClose: 7000,
        closeButton: <ToastClose />,
        icon: false,
        position: getPosition(),
      }
    );
  },
  notify: (label: JSX.Element, onClick: () => void, toastId?: string) => {
    toast(<ToastBody label={label} type="notify" />, {
      autoClose: 10_000,
      className: 'notify',
      closeButton: false,
      onClick,
      position: getPosition(),
      toastId: toastId || 'notify',
    });
  },
  success: (label: string, shouldTranslate = false) => {
    toast.success(
      <ToastBody
        label={label}
        shouldTranslate={shouldTranslate}
        type="success"
      />,
      {
        autoClose: 4000,
        closeButton: <ToastClose />,
        position: getPosition(),
      }
    );
  },
};

export default toastUtils;

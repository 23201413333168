import apiClient, { API_TYPES } from '@services/hafh/api';
import { Area, SearchProperty } from '@utils/types';

const api = apiClient(API_TYPES.API);

type Action = { payload: { results: (Area | SearchProperty)[] }; type: string };
type InitialState = { results: (Area | SearchProperty)[] };

const initialState = {
  results: [],
};

// action types
export const SET_RESULT = 'hafh/search/SET_RESULT';

// reducers
const search = (state: InitialState = initialState, action: Action) => {
  switch (action.type) {
    case SET_RESULT:
      return {
        ...state,
        results: action.payload.results || [],
      };
    default:
      return state;
  }
};

// action creators
export const updateSearchResult = (result = {}) => ({
  payload: result,
  type: SET_RESULT,
});

// actions
export const autoComplete = (q, locale) => async (dispatch) => {
  const res = await api.get('search', { q }, locale);

  if (res) {
    dispatch(updateSearchResult(res));
  }

  return res;
};

export const getActivePropertiesAndAreas =
  (propertyIds, areaIds, locale) => async () => {
    const res = await api.get(
      'search/history',
      { area_ids: areaIds, property_ids: propertyIds },
      locale
    );

    return res;
  };

export default search;

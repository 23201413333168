import { Dispatch } from 'redux';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { Property } from '@services/hafh/types/generated';
import { HERO_IMAGES_PROPERTIES_LIST } from '@utils/constants';
import { LANG_LOCALE, PayloadAction } from '@utils/types';

const api = apiClient(API_TYPES.API);
const initialState: Property[] = [];

export const UPDATE_HERO_IMAGES = 'hafh/heroImages/UPDATE_HERO_IMAGES';

const HeroImages = (
  state = initialState,
  action: PayloadAction<Property[]>
) => {
  if (action.type === UPDATE_HERO_IMAGES) {
    return [...action.payload];
  }

  return state;
};

const updateHeroImages = (properties: Property[]): any => ({
  payload: properties,
  type: UPDATE_HERO_IMAGES,
});

export const getHeroImages =
  (locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<PayloadAction<Property[]>>) => {
    const properties: Property[] = await api.get(
      'properties',
      { id: HERO_IMAGES_PROPERTIES_LIST },
      locale
    );

    if (properties) {
      dispatch(updateHeroImages(properties));
    }

    return properties;
  };

export default HeroImages;

import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .color-blue {
    path {
      fill: ${COLORS.primary};
    }
  }
  .color-red {
    path {
      fill: ${COLORS.red700};
    }
  }
  .color-purple700 {
    path {
      fill: ${COLORS.purple700};
    }
  }
`;

export default styles;

import { Dispatch } from 'react';
import { User } from 'firebase/auth';
import apiClient, { API_TYPES } from '@services/hafh/api';
import { PendingCoins } from '@services/hafh/types/generated';
import toast from '@utils/toast';
import { LANG_LOCALE, PayloadAction } from '@utils/types';

const api = apiClient(API_TYPES.API);

const initialState: PendingCoins = null;

// action types
export const SET_PENDING_BONUS_COINS =
  'hafh/pendingBonusCoins/SET_PENDING_BONUS_COINS';

// reducers
const pendingBonusCoins = (
  state: PendingCoins = initialState,
  action: { payload: PendingCoins; type: string }
) => {
  if (action.type === SET_PENDING_BONUS_COINS) {
    return action.payload;
  }

  return state;
};

// actions
export const getPendingBonusCoins =
  (authUser: User, locale: LANG_LOCALE) =>
  async (dispatch: Dispatch<PayloadAction<PendingCoins>>) => {
    const res = await api.get('neighbors/pending_coins', {}, locale, authUser);

    if (res) {
      dispatch({
        payload: res,
        type: SET_PENDING_BONUS_COINS,
      });
    }
  };

export const putPendingBonusCoins =
  (authUser: User, locale: string, pendingCoinIds: number[]) => async () => {
    const res = await api.put(
      'neighbors/pending_coins/claim',
      { neighbor_pending_coin_ids: pendingCoinIds },
      locale,
      authUser
    );

    if (res) {
      toast.success('success.pendingBonusCoins', true);
    }
  };

export default pendingBonusCoins;
